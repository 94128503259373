import { useContext } from "react";
import {
  Td,
  Th,
  Tr,
  Text,
  Link,
  Table,
  Tbody,
  Thead,
  Heading,
  useTheme,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";

import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown, { Components } from "react-markdown";
import { BotMessageContext } from "components/chat/response/BotMessage";

import { hexToRgba } from "utils/helpers";
import { SourceProps } from "models/chat/MessageProps";
import { addCitationsToReply, getSourceId } from "../helpers";

interface Props {
  reply: string;
  sources?: SourceProps[];
}

function MarkedReply({ reply, sources = [] }: Props) {
  // Theme
  const { colors } = useTheme();

  // Context
  const { setOpenAccordionIndex, scrollToAccordion } =
    useContext(BotMessageContext);

  // Handlers
  function handleCiteClick(cite: string, index: number) {
    if (!cite) return;

    setOpenAccordionIndex(index);
    setTimeout(() => scrollToAccordion(), 50);
  }

  const ChakraComponents: Components = {
    // Custom span handling
    span: ({ node, ...props }) => {
      // Get citationID
      const id = props.children;

      const sourceIdRegex = /^([a-zA-Z0-9]{24})$/;
      const isValidCitationId = sourceIdRegex.test(id as string); // true if id is 24-length alphanumeric string

      if (isValidCitationId) {
        // get corresponding source id
        const srcId = getSourceId(sources, id as string);

        // if source not found in sources array, don't display associated citation
        if (srcId === -1) return;

        return (
          <Text
            as="span"
            display={"inline-flex"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            fontSize={"10px"}
            lineHeight={"1"}
            minH={"fit-content"}
            minW={"fit-content"}
            fontWeight={"400"}
            size={"sm"}
            px={1}
            py={0.5}
            mx={"2px"}
            borderRadius={"4px"}
            borderWidth={1}
            borderColor={hexToRgba(colors.highlight.primary, 0.16)}
            color={"highlight.primary"}
            bg={hexToRgba(colors.highlight.primary, 0.12)}
            _hover={{
              transition: "all 0.3s ease",
              borderColor: hexToRgba(colors.highlight.primary, 0.5),
            }}
            transition={"all 0.3s ease"}
            onClick={() => handleCiteClick(id as string, srcId)}
          >
            {srcId + 1}
          </Text>
        );
      } else {
        // For all other spans, return them as is
        return (
          <Text
            as="span"
            fontSize={{ lg: "14px", xl: "15px" }}
            lineHeight={"1.7"}
            color={colors.gray[600]}
            {...props}
          >
            {id}
          </Text>
        );
      }
    },
    // Main Heading (H1)
    h1: ({ node, ...props }) => (
      <Heading
        as={"h1"}
        lineHeight={"1.7"}
        color={colors.gray[600]}
        fontSize={{ lg: "20px", xl: "22px" }}
      >
        {props.children}
      </Heading>
    ),
    // Secondary Heading (H2)
    h2: ({ node, ...props }) => {
      return (
        <Heading
          as={"h2"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "19px", xl: "20px" }}
        >
          {props.children}
        </Heading>
      );
    },
    // Section Heading (H3)
    h3: ({ node, ...props }) => {
      return (
        <Heading
          as={"h3"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "18px", xl: "19px" }}
        >
          {props.children}
        </Heading>
      );
    },
    // Subsection Heading (H4)
    h4: ({ node, ...props }) => {
      return (
        <Heading
          as={"h4"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "17px", xl: "18px" }}
        >
          {props.children}
        </Heading>
      );
    },
    // Smaller Subsection (H5)
    h5: ({ node, ...props }) => {
      return (
        <Heading
          as={"h5"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "16px", xl: "17px" }}
        >
          {props.children}
        </Heading>
      );
    },
    // Smallest Heading (H6)
    h6: ({ node, ...props }) => {
      return (
        <Heading
          as={"h6"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "15px", xl: "16px" }}
        >
          {props.children}
        </Heading>
      );
    },
    p: ({ node, ...props }) => {
      return (
        <Text
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "14px", xl: "15px" }}
        >
          {props.children}
        </Text>
      );
    },
    ul: ({ node, ...props }) => {
      return (
        <UnorderedList
          styleType={"disc"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "14px", xl: "15px" }}
        >
          {props.children}
        </UnorderedList>
      );
    },
    ol: ({ node, ...props }) => {
      return (
        <OrderedList
          styleType={"decimal"}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "14px", xl: "15px" }}
        >
          {props.children}
        </OrderedList>
      );
    },
    li: ({ node, ...props }) => {
      return (
        <ListItem
          lineHeight={"1.7"}
          color={colors.gray[600]}
          fontSize={{ lg: "14px", xl: "15px" }}
          _last={{ mb: 1 }}
        >
          {props.children}
        </ListItem>
      );
    },
    a: ({ node, ...props }) => {
      return (
        <Link
          color={"blue.500"}
          href={props.href}
          lineHeight={"1.7"}
          isExternal
          fontSize={{ lg: "14px", xl: "15px" }}
        >
          {props.children}
        </Link>
      );
    },
    table: ({ node, ...props }) => {
      return (
        <Table
          variant={"striped"}
          colorScheme={"gray"}
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Table>
      );
    },
    thead: ({ node, ...props }) => {
      return (
        <Thead
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Thead>
      );
    },
    tbody: ({ node, ...props }) => {
      return (
        <Tbody
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Tbody>
      );
    },
    tr: ({ node, ...props }) => {
      return (
        <Tr
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Tr>
      );
    },
    th: ({ node, ...props }) => {
      return (
        <Th
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Th>
      );
    },
    td: ({ node, ...props }) => {
      return (
        <Td
          fontSize={{ lg: "14px", xl: "15px" }}
          lineHeight={"1.7"}
          color={colors.gray[600]}
          {...props}
        >
          {props.children}
        </Td>
      );
    },
  };

  const processedReply = addCitationsToReply(sources, reply);

  return (
    <ReactMarkdown
      components={ChakraComponents}
      rehypePlugins={[rehypeRaw]} // parse raw HTML
      remarkPlugins={[remarkGfm]} // provide GitHub Flavored Markdown
    >
      {processedReply}
    </ReactMarkdown>
  );
}

export default MarkedReply;
