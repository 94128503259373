const PapersTypesColors = [
  "221 107 102",
  "99 179 237",
  "255 166 43",
  "79 209 197",
  "224 98 174",
  "131 120 234",
  "203 213 224",
];

export function getPaperColor(
  paperType: string | undefined,
  percent: number = 100
): string {
  switch (paperType?.toLowerCase()) {
    case "journal_article":
      return `rgba(${PapersTypesColors[0]}/${percent}%)`;
    case "observational study":
      return `rgba(${PapersTypesColors[1]}/${percent}%)`;
    case "review":
      return `rgba(${PapersTypesColors[2]}/${percent}%)`;
    case "interventional study":
      return `rgba(${PapersTypesColors[3]}/${percent}%)`;
    case "case report":
      return `rgba(${PapersTypesColors[4]}/${percent}%)`;
    case "experimental study":
      return `rgba(${PapersTypesColors[5]}/${percent}%)`;
    case "other":
      return `rgba(${PapersTypesColors[6]}/${percent}%)`;

    default:
      return `rgba(${PapersTypesColors[6]}/${percent}%)`;
  }
}

export const truncateString = (str: string, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

export function indicationToColor(str: string) {
  if (typeof str === "string") {
    const colors = [
      "#B1E89E",
      "#C3E6CB",
      "#CBF2FF",
      "#ADD8E6",
      "#A1D3FF",
      "#A1E8E5",
      "#52CEBE",
      "#9DB3FF",
      "#A1A1E8",
      "#B7A8F1",
      "#C5A1FF",
      "#FFA1D3",
      "#FFC0CB",
      "#df8e98",
      "#FFA1A1",
      "#FFC1A1",
      "#FFD59D",
    ];
    return hashColor(str, colors);
  }
  return "#e2e8f0";
}

export function categoryToColor(str: string) {
  const colors = [
    "#f58d42",
    "#7c5b20",
    "#6e706b",
    "#5a8e78",
    "#6c4d7b",
    "#47697d",
    "#f4aa4b",
    "#4c8257",
    "#886b53",
    "#c0738f",
    "#60538f",
    "#8f6c5f",
  ];
  return hashColor(str, colors);
}

function hashColor(str: string, colors: string[]) {
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

export function stringToColor(str: string) {
  if (!str) return;

  const colors = [
    "#555E50",
    "#008A0D",
    "#161FA8",
    "#281F5D",
    "#FD2400",
    "#E49FF6",
    "#EB7013",
    "#930081",
    "#F7BBC7",
    "#00F60B",
  ];
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}
