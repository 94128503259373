import { Box, Card, Flex, useTheme, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { hexToRgba } from "utils/helpers";
import { ChatbotContext } from "views/chatbot";
import { useContext } from "react";
import HumanMessageFiles from "./HumanMessageFiles";
import { ConversationProps } from "models/chat/MessageProps";

interface HumanMessageProps {
  botResponse?: ConversationProps;
  questionOnWait?: string;
}

export default function HumanMessage({
  botResponse,
  questionOnWait,
}: HumanMessageProps) {
  const { attachedFiles, uploadedAudio } = useContext(ChatbotContext);
  const { colors } = useTheme();
  const { isGuideOpen } = useSelector(selectCurrentGuideData);

  // Helper function to filter out empty blobs
  const filterFiles = (files: (File | Blob | string)[]) =>
    files.filter((file) => {
      // Check if it's a Blob and its size > 0, or it's a string
      if (file instanceof Blob) {
        return file.size > 0;
      }
      // Strings or files that aren't blobs are passed through
      return true;
    });

  const renderFiles = (
    files: (File | Blob | string)[],
    source: "local" | "server"
  ) => (
    <Flex justifyContent="flex-end" mb="8px">
      {files.map((file, index) => (
        <Box key={index} mr={files.length > 1 ? "20px" : "0"}>
          <HumanMessageFiles file={file} source={source} />
        </Box>
      ))}
    </Flex>
  );

  const question = botResponse?.messages?.slice(-1)[0]?.human || "";

  const botHumanFiles = botResponse?.human_attachments ?? [];
  const botHumanAudio = botResponse?.human_audio
    ? [botResponse.human_audio]
    : [];

  const localFiles = attachedFiles;
  const localAudio = uploadedAudio ? [uploadedAudio] : [];
  const hasLocalFiles = !!attachedFiles.length ;
  const hasBotAttachment = botResponse?.human_attachments

  // Filter out empty blobs before rendering
  const filteredBotHumanAudio = filterFiles(botHumanAudio);
  const filteredLocalAudio = filterFiles(localAudio);
  const renderLocalFiles = hasLocalFiles && !hasBotAttachment;

  const hasQuestion =
    (question || questionOnWait) &&
    question !== "About this image" &&
    questionOnWait !== "About this image";

  return (
    <Box>
      {/* Render server files and audio if present */}
      {(botHumanFiles.length > 0 || filteredBotHumanAudio.length > 0) && (
        <>
          {botHumanFiles.length > 0 && renderFiles(botHumanFiles, "server")}
          {filteredBotHumanAudio.length > 0 &&
            !hasQuestion &&
            renderFiles(filteredBotHumanAudio, "server")}
        </>
      )}

      {/* // Render local files onlys if no server files/audio are present */}
      {botHumanFiles.length === 0 && filteredBotHumanAudio.length === 0 && (
        <>
          {renderLocalFiles
            ? renderFiles(localFiles, "local")
            : !hasQuestion && renderFiles(filteredLocalAudio, "local")}
        </>
      )}

      {/* Ensure message text renders regardless of file uploads */}
      {hasQuestion && (
        <Card
          p={4}
          borderRadius="20px"
          position="relative"
          boxShadow="none"
          bg={
            isGuideOpen ? "transparent" : hexToRgba(colors.secondary[100], 0.8)
          }
        >
          <Text
            color="gray.700"
            lineHeight="1.3"
            fontSize={{ lg: "14px", "2xl": "15px" }}
            textAlign="left"
          >
            {questionOnWait || question}
          </Text>
        </Card>
      )}
    </Box>
  );
}
