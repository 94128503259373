import { useEffect, useMemo, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";

// Redux imports
import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

// Hooks imports
import usePostWS, { TeamSocketContext } from "hooks/posts/usePostWS";
import useEmbeddingProcess from "hooks/literatureEmbedding/useEmbeddingProcess";
import useNotificationsWS from "hooks/notifications/useNotificationsWS";

import {
  Flex,
  useTheme,
  useMediaQuery,
  Box,
  ChakraProvider,
  useColorMode,
} from "@chakra-ui/react";

import Nav from "layouts/nav";

// Views imports
import Team from "views/team";
import Docs from "views/docs";
import Home from "views/home";
import ChatbotView from "views/chatbot";
import DataLibrary from "views/library";
import LoginView from "views/auth/login";
import Pricing from "views/home/pricing";
import MobileView from "views/MobileView";
import CorePanel from "views/library/core";
import OAuth2View from "views/auth/oauth2";
import AuthLayout from "views/auth/AuthLayout";
import PageNotFound from "views/PageNotFound";
import MembersPanel from "views/team/members";
import Unauthorized from "views/Unauthorized";
import RegisterView from "views/auth/register";
import ActivityPanel from "views/team/activity";
import PostsPanel from "views/team/posts";
import Bookmarks from "views/library/bookmarks";
import ChatPanel from "views/chatbot/ChatPanel";
import AcceptInvite from "views/team/acceptInvite";
import ToolView from "views/library/core/tools/ToolView";
import ResetPasswordView from "views/auth/resetPassword";
import SourceTable from "views/library/core/SourceTable";
import SourcesList from "views/library/core/SourcesList";
import FoldersList from "views/library/bookmarks/FoldersList";
import CoreLayersList from "views/library/core/CoreLayersList";
import SourceItemDetails from "views/library/core/SourceItemDetails";

// Components imports
import TourGuide from "components/guideTour/TourGuide";
import HelpButtons from "components/layout/HelpButtons";
import RateLimitModal from "components/ui/RateLimitModal";
import RequireAuth from "components/authforms/RequireAuth";
import { ThemeProps } from "components/userMenu/Appearance";
import NewChatPanel from "components/chat/new/NewChatPanel";
import PersistLogin from "components/authforms/PersistLogin";
import AutoRestartModal from "components/ui/AutoRestartModal";
import EmbeddingLoading from "components/papers/EmbeddingLoading";
import FolderContent from "components/library/bookmarks/folder/FolderContent";

import { PERMISSIONS, TYPES } from "utils/premissions";
import { useVersionAPI } from "services/version.service";
import { useLastViewPath } from "hooks/library/persistPath";

// Theme imports
import generateTheme from "theme";
import { GetGradients } from "utils/gradients";

interface RoutesWrapperProps {
  mode: string | null;
  onToggle: () => void;
}

const loadRDKitScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.head.appendChild(script);
  });
};

function Layout({ onToggle }: ThemeProps) {
  // Hooks
  const { user } = useSelector(selectCurrentAuthData);
  const { checkForEmbeddingsInProgress } = useEmbeddingProcess();
  const notificationsWebSocket = useNotificationsWS();
  const { isGuideOpen, initialSeen: initialGuideSeen } = useSelector(
    selectCurrentGuideData
  );

  // Theme
  const { colors } = useTheme();
  const { lGradient } = GetGradients();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgGradient = isLight
    ? lGradient
    : `linear(to-b, ${colors.dark[400]}, ${colors.dark[400]})`;
  const borderColor = isLight ? "transparent" : "dark.600";

  const state = window.localStorage.getItem("initialRateLimitSeen");
  const initialRateLimitSeen = state ? JSON.parse(state) : false;
  const unlimitedRates =
    user?.user_type === "premium" ||
    user?.user_type === "teams" ||
    user?.permissions?.includes("teams_invitee");

  // State
  const [showRateModal, setShowRateModal] = useState(false);

  // Team Chat Socket Connection Init
  let { teamSocket, teamMessages, setTeamMessages, teamSocketConnected } =
    usePostWS();

  useEffect(() => {
    const initRDKit = async () => {
      try {
        // Load the RDKit script dynamically
        await loadRDKitScript(
          "https://unpkg.com/@rdkit/rdkit/dist/RDKit_minimal.js"
        );

        if (typeof window.initRDKitModule === "function") {
          const instance = await window.initRDKitModule();
          window.RDKit = instance;
        } else {
          throw new Error("initRDKitModule is not available on window");
        }
      } catch (error) {
        console.error("Failed to initialize RDKit:", error);
      }
    };

    initRDKit();
    checkForEmbeddingsInProgress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialGuideSeen && !initialRateLimitSeen && !unlimitedRates) {
      setShowRateModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGuideSeen]);

  return (
    <Flex
      position={"relative"}
      overflow={"hidden"}
      bgGradient={bgGradient}
      p={[null, null, null, 2, 3, 4]}
      h={"100vh"}
    >
      <Flex w={"100%"} gap={[null, null, null, 2, 3, 4]}>
        {/* navbar */}
        <Nav onToggle={onToggle} />

        {/* main panel */}
        <Flex
          position={"relative"}
          w={"100%"}
          borderRadius={"10px"}
          borderColor={borderColor}
          bg={"background"}
          borderWidth={1}
        >
          <TeamSocketContext.Provider
            value={{
              teamSocket: teamSocket,
              teamMessages: teamMessages,
              setTeamMessages: setTeamMessages,
              teamSocketConnected: teamSocketConnected,
            }}
          >
            <Outlet />
          </TeamSocketContext.Provider>

          {/* Embedding progress bar */}
          <EmbeddingLoading />
        </Flex>
      </Flex>

      {/* Feedback & Guide buttons */}
      <HelpButtons />

      {/* Tour guide */}
      {isGuideOpen && <TourGuide />}

      {/* Display rate limit modal */}
      {!initialRateLimitSeen && (
        <RateLimitModal
          isOpen={showRateModal}
          onClose={() => {
            window.localStorage.setItem(
              "initialRateLimitSeen",
              JSON.stringify(true)
            );
            setShowRateModal(false);
          }}
        />
      )}
    </Flex>
  );
}

function AppWrapper() {
  const location = useLocation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // State
  const [showAutoReload, setShowAutoReload] = useState(false);
  const [cancelReload, setCancelReload] = useState(false);
  const cancelReloadRef = useRef(cancelReload);
  const { setLastPath } = useLastViewPath();

  // APIs
  const { getApiVersion, isApiVersionLatest, setApiVersion } = useVersionAPI();

  useEffect(() => {
    const { pathname } = location;
    const library = ["core", "bookmarks"].find((segment) =>
      pathname.includes(segment)
    );

    if (library) {
      setLastPath(pathname, library);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setLastPath]);

  const hiddenPathsOnSmallScreen = [
    "/chat",
    "/team",
    "/models",
    "/data",
    "/docs",
    "/pricing",
  ];

  const isHiddenOnSmallScreen = hiddenPathsOnSmallScreen.some((path) =>
    location?.pathname?.startsWith(path)
  );

  const pathsWithoutAutoReload = [
    "/chat",
    "/login",
    "/register",
    "/pricing",
    "/reset-password",
  ];

  const noAutoReload = pathsWithoutAutoReload.some((path) =>
    location?.pathname?.startsWith(path)
  );

  useEffect(() => {
    cancelReloadRef.current = cancelReload;
  }, [cancelReload]);

  useEffect(() => {
    if (!noAutoReload && process.env.NODE_ENV === "production") {
      // Check for updates every 5 minutes (300,000 milliseconds) 300000
      const intervalId = setInterval(getVersion, 300000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noAutoReload]);

  const getVersion = async () => {
    await getApiVersion()
      .then((res) => {
        if (isApiVersionLatest(res) && !cancelReloadRef.current) {
          // Reload app
          setShowAutoReload(true);
          setApiVersion(res);
          setTimeout(() => {
            if (!cancelReloadRef.current) {
              setCancelReload(false);
              setShowAutoReload(false);
              window.location.reload();
            }
          }, 10000);
        }
        setApiVersion(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isMobileView && isHiddenOnSmallScreen)
    return <MobileView isMobileView={isMobileView} />;

  const isVisible = !isMobileView || !isHiddenOnSmallScreen;

  return (
    <Box visibility={isVisible ? "visible" : "hidden"}>
      <Outlet />
      {/* Display auto-reload modal */}
      <AutoRestartModal
        isOpen={showAutoReload}
        setCancelReload={setCancelReload}
        setShowAutoReload={setShowAutoReload}
        onClose={() => {
          setShowAutoReload(false);
        }}
      />
    </Box>
  );
}

function RoutesWrapper({ mode, onToggle }: RoutesWrapperProps) {
  // Hooks
  const location = useLocation();
  const { setColorMode } = useColorMode();

  // Force light theme on Homepage
  const theme = useMemo(() => {
    const isAtHome = location?.pathname === "/";
    const isAtLogin = location?.pathname === "/login";
    const isAtRegister = location?.pathname === "/register";
    const isAtPasswordReset = location?.pathname === "/reset-password";

    const forcedLightMode =
      isAtHome || isAtLogin || isAtRegister || isAtPasswordReset;

    setColorMode(forcedLightMode ? "light" : mode);

    return forcedLightMode ? generateTheme("light") : generateTheme(mode);
  }, [location, mode, setColorMode]);

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route element={<AppWrapper />}>
          {/* Public routes */}
          <Route element={<PersistLogin />}>
            <Route index element={<Home />} />
            <Route path="unauthorized" element={<Unauthorized />} />

            {/* Auth */}
            <Route element={<AuthLayout />}>
              <Route path="login" element={<LoginView />} />
              <Route path="register" element={<RegisterView />} />
              <Route path="reset-password" element={<ResetPasswordView />} />
            </Route>

            <Route path="oauth2/idpresponse" element={<OAuth2View />} />

            {/* Protected routes */}
            <Route
              element={<RequireAuth allowedTypes={[...Object.values(TYPES)]} />}
            >
              <Route element={<Layout onToggle={onToggle} />}>
                {/* data library routes */}
                <Route path="data" element={<DataLibrary />}>
                  <Route index element={<Navigate to="/data/core" />} />

                  {/* CORE */}
                  <Route path="core" element={<CorePanel />}>
                    <Route index element={<CoreLayersList />} />
                    <Route path=":layerName" element={<SourcesList />} />
                    <Route
                      path=":layerName/data/:source"
                      element={<SourceTable />}
                    />
                    <Route
                      path=":layerName/data/:source/:sourceItemId"
                      element={<SourceItemDetails />}
                    />
                    <Route
                      path=":layerName/tools/:toolName"
                      element={<ToolView />}
                    />
                  </Route>

                  {/* LIBRARY */}
                  <Route path="bookmarks" element={<Bookmarks />}>
                    <Route index element={<FoldersList />} />
                    <Route path=":folderId" element={<FolderContent />} />
                  </Route>
                </Route>

                {/* chat routes */}
                <Route path="chat/" element={<ChatbotView />}>
                  <Route index element={<NewChatPanel />} />
                  <Route path=":id" element={<ChatPanel />} />
                </Route>

                {/* Teams routes */}
                <Route
                  element={
                    <RequireAuth
                      allowedTypes={[TYPES.TEAMS]}
                      allowedPermissions={[PERMISSIONS.TEAMS_INVITEE]}
                    />
                  }
                >
                  <Route path="/team" element={<Team />}>
                    <Route path="" element={<MembersPanel />} />
                    <Route path="folders" element={<Bookmarks />}>
                      <Route index element={<FoldersList />} />
                      <Route path=":folderId" element={<FolderContent />} />
                    </Route>
                    <Route path="posts" element={<PostsPanel />} />
                    <Route path="activity" element={<ActivityPanel />} />
                  </Route>
                </Route>

                {/* TEAM INVITES */}
                <Route path="accept-invite" element={<AcceptInvite />} />
              </Route>
            </Route>

            <Route element={<RequireAuth allowedTypes={[TYPES.INTERNAL]} />}>
              <Route path="docs" element={<Docs />} />
            </Route>

            <Route
              element={<RequireAuth allowedTypes={[...Object.values(TYPES)]} />}
            >
              <Route path="pricing" element={<Pricing />} />
            </Route>
          </Route>
          <Route path="not-found" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default function AppRoutes() {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const { toggleColorMode } = useColorMode();

  // Handlers
  function handleToggleTheme() {
    toggleColorMode();
  }

  return (
    <Router>
      <RoutesWrapper mode={colorMode} onToggle={handleToggleTheme} />
    </Router>
  );
}
