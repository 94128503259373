import { useRef, useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import Loading from "components/ui/Loading";
import $3Dmol from '3dmol';

interface ProteinStructureProps {
  sequence: string | undefined;
}

function ProteinStructure({ sequence }: ProteinStructureProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const viewerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!sequence) return;

    const fetchProteinStructure = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch("http://34.89.150.177/visualizeProteins/", {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query_sequence: sequence }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch the protein structure");
        }

        const data = await response.json();

        // Render 3Dmol.js model
        if (viewerRef.current) {
          const viewer = $3Dmol.createViewer(viewerRef.current, {
            defaultcolors: $3Dmol.elementColors.Jmol, // Ensure proper color spec type
          });

          viewer.addModel(data.structure, "pdb"); // Assuming `data.structure` contains PDB format
          viewer.setStyle({}, { cartoon: { color: 'spectrum' } });
          viewer.zoomTo();
          viewer.render();
        }
      } catch (error) {
        console.error("Error fetching the structure:", error);
        setError(error instanceof Error ? error.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    // Fetch protein structure
    fetchProteinStructure();

    // Cleanup viewer on component unmount or re-render
    return () => {
      if (viewerRef.current) {
        viewerRef.current.innerHTML = ""; // Clear viewer contents
      }
    };
  }, [sequence]);

  return (
    <Flex align="center" gap={2} w="fit-content">
      {loading && <Loading message="Generating structure..." />}
      {error && <Text color="red.500">{error}</Text>}
      {!loading && !error && <div ref={viewerRef} style={{ width: "800px", height: "600px" }} />}
    </Flex>
  );
}

export default ProteinStructure;
