import { useQueryClient } from "@tanstack/react-query";

export function useLastViewPath() {
  const queryClient = useQueryClient();

  const getLastPath = (library?: string) => {
    return queryClient.getQueryData(["lastLibraryPath"]);
  };

  const getSpecificLibraryLastPath = (library?: string) => {
    if (library === "core") {
      return queryClient.getQueryData(["lastCorePath"]);
    } else {
      return queryClient.getQueryData(["lastBookmarkPath"]);
    }
  };

  const setLastPath = (path: string, library?: string) => {
    if (library === "core") {
      queryClient.setQueryData(["lastCorePath"], path);
    } else {
      queryClient.setQueryData(["lastBookmarkPath"], path);
    }

    queryClient.setQueryData(["lastLibraryPath"], path);
  };

  return { getLastPath, setLastPath, getSpecificLibraryLastPath };
}
