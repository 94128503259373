import { Navigate, useParams } from "react-router-dom";

import AssayView from "components/library/core/AssayView";
import MoleculeView from "components/library/core/MoleculeView";
import LiteratureView from "components/library/core/LiteratureView";

import { CustomScrollBar } from "components/ui/CustomScrollBar";
import ProteinView from "components/library/core/protein/ProteinView";

const CustomScrollBarStyle = {
  height: "100%",
  width: "100%",
  padding: "0 6px 0 0",
};

const SourceItemDetails = () => {
  const { source } = useParams();
  const key = source?.toUpperCase() || "";

  // const mRNAProteinSource = getMRNAProteinSource("protein-sequences");

  if (!["MOLECULES", "LITERATURE", "ASSAYS", "PROTEINS"].includes(key)) {
    return <Navigate to={"/not-found"} replace />;
  }

  return (
    <CustomScrollBar style={CustomScrollBarStyle} overflowX={"hidden"}>
      {key === "MOLECULES" ? (
        <MoleculeView />
      ) : key === "ASSAYS" ? (
        <AssayView />
      ) : key === "PROTEINS" ? (
        <ProteinView />
      ) : (
        <LiteratureView />
      )}
    </CustomScrollBar>
  );
};

export default SourceItemDetails;
