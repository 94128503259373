import { Navigate, useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import {
  ProprietarySourceProps,
  SourceProps,
  getCoreSource,
  getProprietarySource,
  getMRNASource,
} from "../helpers";
import AgnosticDataDisplay from "components/library/core/AgnosticDataDisplay";

const SourceTable = () => {
  const { source, layerName } = useParams();

  const coreSource = getCoreSource(source);
  const mRNASource = getMRNASource(source);
  const proprietarySources = getProprietarySource(source);

  if (
    ![
      "MOLECULES",
      "LITERATURE",
      "ASSAYS",
      "PROPRIETARY",
      "PROTEINS",
      "3'UTR",
      "FIVE-UTRs",
    ].includes(source?.toUpperCase() || "")
  ) {
    return <Navigate to={"/not-found"} replace />;
  }

  return (
    <>
      {!coreSource && !proprietarySources && !mRNASource ? (
        <Box>Source not found</Box>
      ) : (
        <AgnosticDataDisplay
          source={
            layerName === "Private"
              ? (proprietarySources as ProprietarySourceProps)
              : layerName === "mRNALake"
              ? (mRNASource as SourceProps)
              : (coreSource as SourceProps)
          }
        />
      )}
    </>
  );
};

export default SourceTable;
