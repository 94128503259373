import { ChangeEvent, KeyboardEvent, useContext, useState } from "react";
import {
  Flex,
  Textarea,
  FormControl,
  InputGroup,
  useToast,
  Icon,
  Text,
} from "@chakra-ui/react";
import { BsSendFill } from "react-icons/bs";
import AttachFile from "./AttachFile";
import { ChatbotContext } from "views/chatbot";
import TextFieldFiles from "./TextFieldFiles";
import AudioRecording from "./AudioRecording";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

const MAX_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB

export default function TextField({
  onSendQuestion,
}: {
  onSendQuestion: (s: string, questionType?: string) => void;
}) {
  // States
  const [question, setQuestion] = useState("");
  const [recording, setRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);

  // Hooks
  const { user } = useSelector(selectCurrentAuthData);
  const {
    attachedFiles,
    setAttachedFiles,
    uploadedAudio,
    setUploadedAudio,
    waitingOnBot,
  } = useContext(ChatbotContext);
  const toast = useToast();

  const hasAttachedFiles = !!attachedFiles?.length;
  // Determine if the question can be submitted
  const canSubmit = question.trim() || hasAttachedFiles || recordedAudio;

  // Handle sending the question
  const handleSend = () => {
    if (canSubmit) {
      const questionType = recordedAudio ? "audio" : "text";
      onSendQuestion(question.trim(), questionType);
      resetFields();
    }
  };

  // Reset input fields after sending
  const resetFields = () => {
    setQuestion("");
    setRecordedAudio(null);
    setRecording(false);
  };

  // Handle file selection
  const handleFileSelect = (files: File[] | null) => {
    if (!files) return;

    const validFiles = files.filter((file) => file.size <= MAX_SIZE_BYTES);
    const invalidFiles = files.filter((file) => file.size > MAX_SIZE_BYTES);

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map((file) => file.name).join(", ");
      toast({
        description: `The following files exceed the maximum allowed size of ${
          MAX_SIZE_BYTES / (1024 * 1024)
        } MB: ${invalidFileNames}. Please upload smaller files.`,
        status: "error",
        position: "top-right",
      });
    }

    setAttachedFiles(validFiles);
  };

  // Handle keydown event for textarea
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Flex
      position={"absolute"}
      bottom={1}
      right={0}
      left={0}
      direction={"column"}
      align={"center"}
      maxW={"90%"}
      w={"900px"}
      mx={"auto"}
      gap={1}
    >
      <FormControl
        position="relative"
        w="100%"
        bg="background"
        borderRadius="16px"
        borderWidth={1}
        borderColor={"gray.200"}
      >
        {/* Display attached files if any and not waiting on bot */}
        {hasAttachedFiles && !waitingOnBot && (
          <TextFieldFiles
            files={attachedFiles}
            onRemoveFile={(i) =>
              setAttachedFiles(
                (prev) => prev?.filter((_, idx) => idx !== i) ?? []
              )
            }
          />
        )}

        <InputGroup minHeight="60px" maxHeight="60px">
          {/* File attachment component */}

          <AttachFile
            onFileSelect={handleFileSelect}
            accept="
              .pdf, .jpeg, .jpg, .png, .gif, .webp, .docx,.ods, .odp,.odt, .txt, 
               .csv, .xls, .xlsx, .ppt, .pptx, .json, .html"
            user={user}
          />

          {/* Textarea for input */}
          {uploadedAudio === null && (
            <Textarea
              value={question}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setQuestion(e.target.value.replace(/(\r\n|\n|\r)/gm, ""))
              }
              onKeyDown={handleKeyDown}
              placeholder={recording ? "Sai Listening" : "Ask Sai..."}
              borderRadius="16px"
              bg="background"
              border="none"
              resize="none"
              minHeight="60px"
              maxHeight="60px"
              pt={"20px"}
              width={"85%"}
              fontSize={{ lg: "14px", xl: "15px" }}
              _focus={{
                borderColor: "transparent",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
          )}

          {/* Audio recording component */}
          {!hasAttachedFiles && !waitingOnBot && (
            <AudioRecording
              setUploadedAudio={setUploadedAudio}
              setRecordedAudio={setRecordedAudio}
              recording={recording}
              setRecording={setRecording}
              user={user}
              recordedAudio={recordedAudio}
            />
          )}

          {/* Send icon with tooltip */}
          {!recording && (
            <Icon
              as={BsSendFill}
              position="absolute"
              top="50%"
              right={4}
              transform="translateY(-50%)"
              boxSize={4}
              color="primary.300"
              cursor={waitingOnBot ? "not-allowed" : "pointer"}
              onClick={waitingOnBot ? undefined : handleSend}
              _hover={{ color: "highlight.primary" }}
            />
          )}
        </InputGroup>
      </FormControl>

      {/* Disclaimer text */}
      <Text
        fontSize={{ lg: "10px", xl: "12px" }}
        color={"gray.500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
      >
        GenAI can make mistakes. Double check important information.
      </Text>
    </Flex>
  );
}
