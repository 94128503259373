import { useContext, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
  Tooltip,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

import { hexToRgba } from "utils/helpers";
import { BotMessageContext } from "../response/BotMessage";
import { ExternalId, PaperProps } from "models/papers/PaperProps";
import { CustomLock, CustomUnLock } from "components/ui/CustomIcons";

import { SiDoi } from "react-icons/si";
import { FaQuoteLeft } from "react-icons/fa";
import { TbAward, TbBlockquote } from "react-icons/tb";
import { BsBookmarkCheck } from "react-icons/bs";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";

import { getPaperColor } from "components/ui/helpers";
import LensImg from "assets/chatbot/lens.png";
import FoldersModal from "components/library/bookmarks/modal/FoldersModal";

interface AccordionSourceProps {
  data: PaperProps;
  index: number;
}

function AccordionSourceItem({ data, index }: AccordionSourceProps) {
  // States
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showFullAbstract, setShowFullAbstract] = useState(false);
  const [borderColor, setBorderColor] = useState<string | undefined>(
    "gray.200"
  ); // NOTE: recognize clicked citation source by border color change

  // Context
  const { openAccordionIndex, openAccordionRef } =
    useContext(BotMessageContext);

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  const bg = isLight ? "background" : hexToRgba(colors.secondary[100], 0.2);
  const linksColor = isLight ? "secondary.400" : "gray.600";
  const linksBgColor = isLight ? "secondary.100" : "gray.200";
  const boxShadow = isLight
    ? "md"
    : "rgb(25, 62, 78, 0.6) 0px 6px 24px 0px, rgb(25, 62, 78, 0.6) 0px 0px 0px 1px";

  const doi = data?.external_ids?.find(
    (id: ExternalId) => id?.type?.toLowerCase() === "doi"
  )?.value;

  const fontSize = useBreakpointValue({ lg: "14px", "2xl": "15px" });

  const processedAbstract = useMemo(() => {
    if (!data?.abstract) {
      return "";
    } else {
      if (showFullAbstract) {
        return data?.abstract;
      } else return data?.abstract?.substring(0, 350) + "...";
    }
  }, [data, showFullAbstract]);

  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  useEffect(() => {
    if (openAccordionIndex === index) {
      setBorderColor("primary.300");

      const timer = setTimeout(() => {
        setBorderColor("gray.200");
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setBorderColor("gray.200");
    }
  }, [openAccordionIndex, index]);

  return (
    <AccordionItem
      position={"relative"}
      borderRadius={"md"}
      transition={"all ease .3s"}
      borderWidth={1}
      borderColor={applyIsGuideOpenStyles ? "transparent" : borderColor}
      bg={applyIsGuideOpenStyles ? "transparent" : bg}
      _hover={{ boxShadow: boxShadow }}
      ref={openAccordionIndex === index ? openAccordionRef : null}
      mb={3}
      p={4}
    >
      {applyIsGuideOpenStyles && (
        <Box
          borderRadius={"md"}
          bg={"rgba(0, 0, 0, 0.3)"}
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          pointerEvents={"none"}
          zIndex={1300}
          border={"none"}
        />
      )}

      <Flex align={"center"} gap={2} justify={"space-between"}>
        <Flex gap={5}>
          {/* Left part */}
          <Flex direction={"column"} justifyContent={"space-between"} pb={5}>
            <Box>
              <Icon as={FaQuoteLeft} color={"gray.500"} boxSize={4} />
            </Box>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              fontSize={"10px"}
              h={4}
              background={hexToRgba(colors.highlight.primary, 0.15)}
              color={"highlight.primary"}
              borderRadius={4}
              fontWeight={"700"}
            >
              {index + 1}
            </Flex>
          </Flex>

          {/* Right part */}
          <Box textAlign={"start"}>
            {/* Title */}
            <Text
              color={"gray.700"}
              fontWeight={"400"}
              fontSize={fontSize}
              lineHeight={"1.3"}
              mb={2}
            >
              {data.title}
            </Text>

            {/* Publication year & Authors */}
            <Box
              fontStyle={"italic"}
              my={1}
              color={"gray.400"}
              fontSize={"sm"}
              fontWeight={"400"}
            >
              {data.year_published || "Year not available"}
              {!!data.authors?.length && ", "}{" "}
              {data.authors?.map(
                (auth) => `${auth.initials}. ${auth.last_name} `
              )}
            </Box>

            <Flex alignItems={"center"} gap={1}>
              {/* Paper Type */}
              <Badge
                fontSize={"9px"}
                fontWeight={"bold"}
                fontFamily={"Poppins, sans-serif"}
                px={2}
                py={0.5}
                color={getPaperColor(data.paper_type)}
                bg={getPaperColor(data.paper_type, 15)}
                borderRadius={4}
                letterSpacing={".025rem"}
              >
                {data.paper_type ?? "Unclassified"}
              </Badge>

              {/* Publisher */}
              {data.source && (
                <Text
                  fontStyle={"italic"}
                  color={"gray.400"}
                  fontSize={"xs"}
                  fontWeight={"400"}
                >
                  {data.source?.publisher}
                </Text>
              )}
            </Flex>
          </Box>
        </Flex>

        <AccordionButton
          p={0}
          w={"fit-content"}
          h={"fit-content"}
          _hover={{ backgroundColor: "transparent" }}
        >
          <AccordionIcon
            as={MdKeyboardDoubleArrowDown}
            color={"gray.500"}
            boxSize={"22px"}
            p={1}
            bg={"gray.100"}
            borderRadius={"50%"}
            transition={"ease-in-out .3s"}
          />
        </AccordionButton>
      </Flex>

      <AccordionPanel py={2} px={0}>
        <Flex direction={"column"} justify={"space-between"} h={"100%"}>
          <Flex direction={"column"}>
            {/* Links */}
            <Flex
              alignItems="center"
              w={"100%"}
              my={2}
              gap={3}
              fontSize={"sm"}
              wrap={"wrap"}
            >
              {/* DOI */}
              {doi && (
                <Link
                  minW={"fit-content"}
                  as={RouterLink}
                  to={`https://www.google.com/search?q=${encodeURIComponent(
                    `doi: ${doi}`
                  )}`}
                  isExternal
                  w={"fit-content"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  color={linksColor}
                >
                  <SiDoi fontSize={"18px"} />
                  <Text
                    fontWeight={"400"}
                    fontSize={[null, null, null, "14px", "14px", "16px"]}
                    color={linksColor}
                  >
                    {doi}
                  </Text>
                </Link>
              )}

              {/* LENS ID */}
              {data.lens_id && (
                <Link
                  color={linksColor}
                  as={RouterLink}
                  to={`https://www.lens.org/lens/scholar/article/${data.lens_id}/main`}
                  isExternal
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Image
                    src={LensImg}
                    maxHeight={"14px"}
                    maxWidth={"14px"}
                    objectFit={"cover"}
                    alt={data.lens_id}
                  />
                  <Text>{data.lens_id}</Text>
                </Link>
              )}

              {/* Open source */}
              {data.is_open_access ? (
                <Flex alignItems={"center"}>
                  <CustomUnLock
                    fill={isLight ? "#4196CE" : "#CACACA"}
                    width="1.25rem"
                    height="1.25rem"
                  />

                  <Badge
                    bg={linksBgColor}
                    borderRadius={5}
                    fontSize={"9px"}
                    color={linksColor}
                  >
                    Open access
                  </Badge>
                </Flex>
              ) : (
                <Flex alignItems={"center"}>
                  <CustomLock fill="#718096" width="1.25rem" height="1.25rem" />
                  <Badge borderRadius={5} fontSize={"9px"} color={"gray.500"}>
                    Restricted access
                  </Badge>
                </Flex>
              )}
            </Flex>

            {/* Abstraction */}
            {!!processedAbstract && processedAbstract?.length && (
              <Box my={4}>
                <>
                  <Badge
                    fontSize={"10px"}
                    mb={3}
                    px={2}
                    py={0.5}
                    color={"gray.500"}
                    bg={"gray.200"}
                    borderRadius={4}
                  >
                    abstract
                  </Badge>
                  <Flex>
                    <Text fontSize={"14px"} color={"gray.600"}>
                      {processedAbstract}
                      <Text
                        cursor={"pointer"}
                        as={"span"}
                        fontSize={"13px"}
                        color={"blue.400"}
                        fontWeight={"500"}
                        onClick={() => setShowFullAbstract((s) => !s)}
                      >
                        {showFullAbstract ? " less" : " more"}
                      </Text>
                    </Text>
                  </Flex>
                </>
              </Box>
            )}
          </Flex>

          {/* Footer */}
          <Flex
            align={"center"}
            justify={"space-between"}
            w={"100%"}
            h={"25px"}
            gap={2}
            mt={4}
          >
            {/* Scholarly citations */}
            <Flex
              h="100%"
              align={"center"}
              px={2}
              color={"gray.600"}
              borderWidth={1}
              borderColor={"gray.400"}
              borderRadius={8}
              fontSize={"13px"}
              fontStyle={"italic"}
            >
              <Flex gap={2} alignItems={"center"}>
                <Text>Scholarly citations</Text>
                <TbBlockquote
                  style={{ transform: "skewX(-10deg)" }}
                  fontSize={"18px"}
                  strokeWidth={"1.5px"}
                />
                <Text>{data?.scholarly_citations_count}</Text>
              </Flex>

              <Center height="100%">
                <Divider
                  orientation="vertical"
                  mx={"2"}
                  borderColor={"gray.400"}
                />
              </Center>
              <Flex gap={2} alignItems={"center"}>
                <Text>Patent citations</Text>
                <TbAward
                  style={{ transform: "skewX(-10deg)" }}
                  fontSize={"18px"}
                  strokeWidth={"1.5px"}
                />
                <Text>{data?.patent_citations_count ?? 0}</Text>
              </Flex>
            </Flex>

            <Tooltip
              label={"Save"}
              bg={"gray.900"}
              color={"gray.100"}
              hasArrow
              p={1.5}
              maxW={[null, null, null, "200px", "220px", "240px"]}
              borderRadius={"4px"}
              fontSize={"12px"}
              boxShadow={"none"}
              arrowSize={6}
            >
              <IconButton
                p={0}
                icon={<BsBookmarkCheck />}
                aria-label={"Save"}
                bg={"transparent"}
                fontSize={[null, null, null, "sm", "sm", "sm"]}
                boxSize={6}
                color={"gray.500"}
                _hover={{ bg: "gray.100", boxShadow: "lg" }}
                _active={{ bg: "gray.100" }}
                onClick={() => setShowSaveModal(true)}
              />
            </Tooltip>
          </Flex>

          {/* Modal */}
          {data?.id && (
            <FoldersModal
              isOpen={showSaveModal}
              onClose={() => setShowSaveModal(false)}
              payload={{
                saveElementPayload: {
                  elementType: "LITERATURE",
                  content: {
                    elementId: data?.id,
                  },
                },
                successMessage: `Literature is successfully saved.`,
              }}
            />
          )}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default AccordionSourceItem;
